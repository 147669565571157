import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: '#FF5100',
    borderRadius: theme.spacing(3),
    padding: theme.spacing(1, 3),
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),
    opacity: '1',
    '&:hover': {
      backgroundColor: '#FF5100',
      opacity: '0.8',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    '&.MuiButton-outlined': {
      backgroundColor: 'transparent',
      color: '#FF5100',
    },
  },
}));

function StandardButton(props) {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      variant="contained"
      color="primary"
      {...props}
    >
      {props.children}
    </Button>
  );
}

export default StandardButton;
