/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import { GET_INCOMING_ORDERS } from 'graphql/query/incomingOrders';
import {
  ACCEPT_INCOMING_ORDER,
  UPDATE_INCOMING_ORDER_STATUS,
} from 'graphql/mutation/incomingOrders';

import { makeStyles, withStyles } from '@material-ui/styles';
import MuiButton from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
// import { ReactComponent as AcceptedIcon } from 'assets/accepted.svg';
// import { ReactComponent as InstructionsIcon } from 'assets/instructions.svg';

import TableLayout from 'components/shared/TableLayout';
import {
  INCOMING_ORDER_CONFIRMATION_STATUS,
  INCOMING_ORDER_SORTING_OPTIONS,
  NOTIFICATION_STATUS,
  INCOMING_ORDER_STATUSES,
  ROWS_PER_PAGE_OPTIONS,
  TABLE_SKELETON_TYPES,
  INCOMING_ORDER_FILTERS_AND_SORTING_KEY,
  INCOMING_ORDERS_DEFAULT_FILTERS_AND_SORTING,
} from 'helpers/constants';
import useMixPanel from 'helpers/useMixPanel';
import AIOrdersTableRow from 'components/OrdersTable/AIProcessedOrders/AIOrderTableRow';
import AIOrderTableStatusLegend from 'components/OrdersTable/AIProcessedOrders/AIOrderTableStatusLegend';
import SwitchOrderTablesActionButtons from 'components/OrdersTable/SwitchOrderTablesActionButtons';
import parseValidationErrors from 'helpers/parseValidationErrors';
import OrderModal from 'components/shared/ModalDialog';
import IncomingOrdersFilters from 'components/OrdersTable/AIProcessedOrders/IncomingOrdersFilters';
import IncomingOrderStartReview from 'components/OrdersTable/AIProcessedOrders/IncomingOrderStartReview';
import getParsedLocalStorageItem from 'helpers/getParsedLocalStorageItem';
import { GET_VENDOR_ACCOUNT } from 'graphql/query/vendors';
import SwitchAITablesButtons from 'components/OrdersTable/SwitchAITablesButtons';

const Button = withStyles(({ spacing }) => ({
  root: {
    height: 40,
    borderRadius: 40,
    marginLeft: spacing(1),
  },
}))(MuiButton);

const useStyles = makeStyles(({ palette, spacing }) => ({
  orderCustomerTitle: {
    width: 300,
  },
  statusLegend: {
    flexDirection: 'row-reverse',
    textAlign: 'center',
  },
  actionButton: {
    background: palette.background.paper,
  },
  numberOfProducts: {
    textAlign: 'center',
  },
  inboxEmail: {
    width: 'fit-content',
    marginTop: spacing(2),
  },
}));

export default function AIProcessedOrders() {
  const { t } = useTranslation();
  const classes = useStyles();
  const mixPanel = useMixPanel();

  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const currentPath = location.pathname;

  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: 10,
    sortBy: INCOMING_ORDER_SORTING_OPTIONS.CreatedAt,
    filters: {
      status: [],
      customerNumber: [],
      unassignedCustomers: false,
    },
    sortOrder: -1,
  });
  const [selectedOrderId, setSelectedOrderId] = React.useState(null);
  const [showSearchResults, setShowSearchResult] = React.useState(false);
  const [isOpenCancelOrderModal, setCancelOrderModal] = React.useState(false);
  const [isOpenConfirmationOrderModal, setConfirmOrderModal] = React.useState(
    false
  );
  const [isFilterModalOpen, setFilterModalOpen] = React.useState(false);

  const [setNotification] = useMutation(SET_NOTIFICATION);
  const [updateOrderStatus] = useMutation(UPDATE_INCOMING_ORDER_STATUS);
  const [acceptIncomingOrder] = useMutation(ACCEPT_INCOMING_ORDER);

  const { data: vendorAccount } = useQuery(GET_VENDOR_ACCOUNT, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const [
    getOrders,
    { loading: ordersLoading, error: ordersError, data },
  ] = useLazyQuery(GET_INCOMING_ORDERS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const preferences = vendorAccount?.vendorAccount?.preferences || {};
  const isAiOrdersEnabled = preferences.aiOrderSettings?.enabled;
  const aiOrdersInboxEmail = preferences.aiOrderSettings?.inboxEmail;

  if (preferences && !isAiOrdersEnabled) {
    history.push('/orders');
  }

  const ordersData = data && data.getIncomingOrders;

  const getPersistedFilters = () => {
    const persistedFilters = getParsedLocalStorageItem(
      INCOMING_ORDER_FILTERS_AND_SORTING_KEY
    );

    if (!persistedFilters) {
      localStorage.setItem(
        INCOMING_ORDER_FILTERS_AND_SORTING_KEY,
        JSON.stringify(INCOMING_ORDERS_DEFAULT_FILTERS_AND_SORTING)
      );
      return INCOMING_ORDERS_DEFAULT_FILTERS_AND_SORTING;
    }
    return persistedFilters;
  };

  const setPersistedFilters = (key, value) => {
    const persistedFilters =
      getParsedLocalStorageItem(INCOMING_ORDER_FILTERS_AND_SORTING_KEY) ||
      INCOMING_ORDERS_DEFAULT_FILTERS_AND_SORTING;

    const updatedFilters = {
      ...persistedFilters,
    };
    updatedFilters[key] = value;

    localStorage.setItem(
      INCOMING_ORDER_FILTERS_AND_SORTING_KEY,
      JSON.stringify(updatedFilters)
    );
  };

  React.useEffect(() => {
    const initPersistedFilters = getPersistedFilters();

    const filters = initPersistedFilters?.filters;

    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const sortByParam = queries.sortBy || initPersistedFilters?.sorting?.sortBy;
    const sortOrderParam =
      +queries.sortOrder || initPersistedFilters?.sorting?.sortOrder;

    getOrders({
      variables: {
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
        filters,
      },
    });

    setState({
      ...state,
      page: pageParam || state.page,
      rowsPerPage: rowsParam || state.rowsPerPage,
      sortBy: sortByParam || state.sortBy,
      sortOrder: sortOrderParam || state.sortOrder,
      filters,
    });
  }, []);

  React.useEffect(() => {
    const totalPages = ordersData?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && totalPages <= state.page;
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(
      state.rowsPerPage
    );

    if (ordersData && (pageNumberExceeded || rowsPerPageExceeded)) {
      history.replace({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        }),
      });
      window.location.reload();
    }
  }, [ordersData]);

  const handleChangePage = async (event, page, search) => {
    const searchPhrase = search || queries.search;
    if (
      ordersData &&
      (ordersData.hasNextPage || page + 1 < ordersData.totalPages)
    ) {
      try {
        await getOrders({
          variables: {
            searchPhrase,
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
            filters: state.filters,
          },
        });
      } catch (error) {
        console.error(error.message);
      }
    }
    setState({ ...state, page });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        page,
      }),
    });
  };

  const handleChangeRowsPerPage = async event => {
    const rowsPerPage = +event.target.value;
    try {
      await getOrders({
        variables: {
          pageSize: rowsPerPage,
          after: 1,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder,
          filters: state.filters,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({ ...state, rowsPerPage, page: 0 });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0,
      }),
    });
  };

  const handleSortRows = sortLabel => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    setPersistedFilters('sorting', { sortBy: sortLabel, sortOrder });
    try {
      await getOrders({
        variables: {
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder,
          filters: state.filters,
        },
      });
    } catch (error) {
      console.error(error.message);
    }
    setState({
      ...state,
      sortOrder,
      sortBy: sortLabel,
      page: 0,
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder,
      }),
    });
  };

  const showCancelOrderModal = orderId => {
    setSelectedOrderId(orderId);
    setCancelOrderModal(true);
  };

  const showAcceptOrderModal = orderId => {
    setSelectedOrderId(orderId);
    setConfirmOrderModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmOrderModal(false);
    setSelectedOrderId(null);
  };

  const handleCloseCancellationModal = () => {
    setCancelOrderModal(false);
    setSelectedOrderId(null);
  };

  const handleAcceptOrder = async orderId => {
    try {
      await acceptIncomingOrder({
        variables: {
          orderId,
          confirmationType:
            INCOMING_ORDER_CONFIRMATION_STATUS.MANUALLY_ACCEPTED,
        },
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('aiOrders.success.order accepted'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
      mixPanel.track('Update Order Status - Success', {
        orderId,
        status: INCOMING_ORDER_STATUSES.Accepted,
      });
    } catch (error) {
      console.error(error);
      setNotification({
        variables: {
          timeout: 4000,
          message: parseValidationErrors(error) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
      mixPanel.track('Update Order Status - Failure', {
        orderId,
      });
    }
    handleCloseConfirmationModal();
  };

  const handleCancelOrder = async () => {
    try {
      await updateOrderStatus({
        variables: {
          orderId: selectedOrderId,
          status: INCOMING_ORDER_STATUSES.Rejected,
        },
        refetchQueries: [{ query: GET_INCOMING_ORDERS }],
        update: (_, result) => {
          const orderResponse = result.data.updateIncomingOrderStatus;
          setNotification({
            variables: {
              timeout: 4000,
              message: t('orders.cancelOrder', {
                orderId: orderResponse?.orderNumber,
              }),
              type: NOTIFICATION_STATUS.SUCCESS,
              isOpen: true,
            },
          });
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: parseValidationErrors(error) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
    handleCloseCancellationModal();
  };

  const handleApplyFilters = async filters => {
    setState({ ...state, filters, page: 0 });
    setFilterModalOpen(false);
    setPersistedFilters('filters', filters);

    try {
      await getOrders({
        variables: {
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder,
          filters,
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: parseValidationErrors(error) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }

    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        page: 0,
      }),
    });
  };

  const tableColumns = [
    {
      title: t('aiOrders.orderNumber'),
      field: INCOMING_ORDER_SORTING_OPTIONS.BusinessName,
      columnStyles: classes.orderCustomerTitle,
      sortable: true,
    },
    {
      title: t('aiOrders.customer'),
      field: INCOMING_ORDER_SORTING_OPTIONS.BusinessName,
      columnStyles: classes.orderCustomerTitle,
      sortable: true,
    },
    {
      title: t('aiOrders.sender'),
      field: INCOMING_ORDER_SORTING_OPTIONS.VendorClientId,
      sortable: true,
    },
    {
      title: t('aiOrders.customer number'),
      field: INCOMING_ORDER_SORTING_OPTIONS.VendorClientId,
      sortable: true,
    },
    {
      title: t('aiOrders.ordered date and time'),
      field: INCOMING_ORDER_SORTING_OPTIONS.CreatedAt,
      sortable: true,
    },
    {
      title: t('aiOrders.delivery date'),
      field: 'deliveryDate',
    },
    // {
    //   title: t('aiOrders.number of products'),
    //   field: 'numberOfProducts',
    //   sortable: false,
    //   columnStyles: classes.numberOfProducts,
    // },
    // {
    //   title: t('aiOrders.confidenceLevel'),
    //   field: 'deadline',
    //   sortable: false,
    // },
    {
      title: t('aiOrders.status'),
      field: 'status',
      sortable: true,
      titleCustomNode: <AIOrderTableStatusLegend />,
      columnStyles: classes.statusLegend,
    },
  ];

  const { page, rowsPerPage, sortBy, sortOrder } = state;

  const handleSearchBar = async searchPhrase => {
    if (!showSearchResults) {
      setShowSearchResult(true);
    }
    setState({ ...state, page: 0 });
    try {
      await getOrders({
        variables: {
          searchPhrase,
          pageSize: state.rowsPerPage,
          after: 1,
          sortOrder,
          filters: state.filters,
        },
      });
    } catch (error) {
      console.error('SEARCH_CUSTOMERS_ERROR', error.message);
    }
  };

  const handleResetSearch = () => {
    setShowSearchResult(false);
    handleSearchBar('');
  };

  const getFiltersLabel = () => {
    const activeFilterTitles = Object.entries(state.filters)
      .map(([filterKey, filterValue]) => {
        if (Array.isArray(filterValue)) {
          return filterValue?.length > 0 ? filterKey : null;
        } else if (filterValue) {
          return filterKey;
        } else {
          return null;
        }
      })
      .filter(Boolean);

    if (activeFilterTitles?.length === 0) {
      return '';
    } else if (activeFilterTitles?.length > 1) {
      return t('aiOrders.number of applied filters', {
        count: activeFilterTitles.length,
      });
    } else {
      return t(`aiOrders.filterTitle.${activeFilterTitles[0]}`);
    }
  };

  const hasError = ordersError || (!ordersLoading && !ordersData);

  return (
    <>
      <TableLayout
        withTableLayoutV2Styles
        title={t('aiOrders.title')}
        subtitleNode={
          aiOrdersInboxEmail && (
            <Grid container item xs={5} justifyContent="flex-end">
              <Typography className={classes.inboxEmail} variant="subtitle2">
                {t('aiOrders.inbox email', { email: aiOrdersInboxEmail })}
              </Typography>
            </Grid>
          )
        }
        // BETA: disable for now
        // subtitleNode={
        //   <Grid container alignItems="center" justifyContent="flex-end">
        //     <Button
        //       className={classes.actionButton}
        //       variant="outlined"
        //       startIcon={<InstructionsIcon />}
        //       onClick={() => history.push('/orders/ai-instructions')}
        //     >
        //       {t('aiOrders.instructions for ai')}
        //     </Button>
        //     <Button
        //       className={classes.actionButton}
        //       variant="outlined"
        //       startIcon={<AcceptedIcon />}
        //       onClick={() => history.push('/orders/ai-auto-accept-rules')}
        //     >
        //       {t('aiOrders.auto accept rules')}
        //     </Button>
        //   </Grid>
        // }
        titleDirection="row"
        columns={tableColumns}
        skeletonType={TABLE_SKELETON_TYPES.ORDERS}
        emptyTableData={hasError || !ordersData?.totalResults}
        emptyTableDataMessage={t('orders.empty orders')}
        tableIsLoading={ordersLoading}
        withActions
        withSearchBar
        headerActionsBeforeSearchBar={
          isAiOrdersEnabled && (
            <SwitchOrderTablesActionButtons
              isAiOrdersActive
              isAiOrdersEnabled={isAiOrdersEnabled}
            />
          )
        }
        headerAdditionalActions={isAiOrdersEnabled && <SwitchAITablesButtons />}
        searchPlaceholder={t('orders.search placeholder')}
        handleSearchBar={handleSearchBar}
        handleShowSearchResult={handleResetSearch}
        headerActions={
          <Grid container alignItems="center" justifyContent="flex-end">
            <Button
              className={classes.actionButton}
              variant="outlined"
              endIcon={<ExpandMoreOutlinedIcon />}
              onClick={() => setFilterModalOpen(true)}
            >
              {t('aiOrders.filter by')}: {getFiltersLabel()}
            </Button>
            {ordersData?.totalResults > 0 && <IncomingOrderStartReview />}
          </Grid>
        }
        page={page}
        sortBy={sortBy}
        sortOrder={sortOrder}
        handleSortRows={handleSortRows}
        rowsPerPage={rowsPerPage}
        totalResults={ordersData?.totalResults ?? 0}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      >
        {ordersData?.results?.map(rowData => (
          <AIOrdersTableRow
            key={rowData._id}
            rowData={rowData}
            handleCancelOrder={showCancelOrderModal}
            handleAcceptOrder={showAcceptOrderModal}
          />
        ))}
      </TableLayout>
      <OrderModal
        isOpen={isOpenCancelOrderModal}
        title={t('orders.order title')}
        onClose={handleCloseCancellationModal}
        actions={[
          {
            label: t('orders.modalUndo'),
            onClick: handleCloseCancellationModal,
          },
          {
            label: t('orders.modal save changes'),
            onClick: handleCancelOrder,
          },
        ]}
      >
        <Typography align="center" variant="body1">
          {t('orders.modal message')}
        </Typography>
      </OrderModal>
      <OrderModal
        isOpen={isOpenConfirmationOrderModal}
        title={t('common.confirm')}
        onClose={handleCloseConfirmationModal}
        actions={[
          {
            label: t('common.cancel'),
            onClick: handleCloseConfirmationModal,
          },
          {
            label: t('common.accept'),
            onClick: () => handleAcceptOrder(selectedOrderId),
          },
        ]}
      >
        <Typography align="center" variant="body1">
          {t('aiOrders.notifications.accept order message')}
        </Typography>
      </OrderModal>
      <IncomingOrdersFilters
        isOpen={isFilterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        activeFilters={state.filters}
        getPersistedFilters={getPersistedFilters}
        handleApplyFilters={handleApplyFilters}
      />
    </>
  );
}
