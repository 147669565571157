/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { DataSheetGrid, keyColumn, textColumn } from 'react-datasheet-grid';
import 'react-datasheet-grid/dist/style.css';
import { v4 as uuid } from 'uuid';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import { SET_NOTIFICATION } from 'graphql/mutation/user';
import {
  GET_CUSTOMER_CONTACT_DATA_FOR_RULES,
  GET_MAPPING_RULES,
} from 'graphql/query/incomingOrders';
import parseValidationErrors from 'helpers/parseValidationErrors';
import CustomerSelectorCell from 'components/AIOrderCustomerRules/CustomerSelectorCell';
import StickyRightColumn from 'components/AIOrderCustomerRules/StickyRightColumn';
import classNames from 'classnames';
import { UPDATE_INCOMING_ORDER_MAPPING_RULES } from 'graphql/mutation/incomingOrders';
import {
  objectHasFieldsExceptId,
  parseCustomerMappingRulesForMutation,
  parseCustomerMappingRulesForUI,
} from 'helpers/mappingRulesHelpers';
import ModalDialog from 'components/shared/ModalDialog';

const useStyles = makeStyles(({ spacing }) => ({
  customerMappingTableActionsContainer: {
    marginBottom: spacing(2),
  },
  customerMappingTableActions: {
    maxHeight: 42,
  },
  legendAccordion: {
    maxWidth: 600,
    borderRadius: `${spacing(1)}px !important`,
  },
  legendAccordionSummary: {
    minHeight: 40,
  },
  legendAccordionButtonExpand: {
    padding: 0,
  },
  backButton: {
    marginRight: spacing(2),
    height: 42,
    paddingRight: spacing(2.5),
  },
  revertChanges: {
    marginRight: spacing(3),
  },
  customerMappingTableClass: {
    '& *': {
      fontSize: 14,
    },
  },
  disableTable: {
    position: 'relative',
    '& *': {
      cursor: 'wait !important',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      cursor: 'wait !important',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },
}));

export const NEW_ROW_ID_PREFIX = 'NEW';

const ADD_NEW_ROW = () => ({
  _id: `${NEW_ROW_ID_PREFIX}-${uuid()}`,
});

function CustomerMappingRules({ customerTableHeight }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [initData, setInitData] = React.useState([]);
  const [data, setData] = React.useState([ADD_NEW_ROW()]);
  const [deletedRows, setDeletedRows] = React.useState([]);

  const [isRevertChangesModalOpen, setRevertChangesModalOpen] = React.useState(
    false
  );

  const [
    getOrderCustomerMappingRules,
    { loading: areRulesLoading, data: customerRules },
  ] = useLazyQuery(GET_MAPPING_RULES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: error => {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: parseValidationErrors(error) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    },
    onCompleted: response => {
      const ruleResponse =
        response?.getIncomingOrderMappingRules?.results || [];
      const parsedRules = parseCustomerMappingRulesForUI(ruleResponse);

      if (parsedRules.length) {
        // console.log('ON___COMPLETED__ >>>>>>>>>>>>>>>>', {
        //   rulesWithExtraRow,
        //   parsedRules,
        //   response,
        // });
        setData(parsedRules);
        setInitData(parsedRules);
      }
    },
  });

  const { data: { vendorCustomers: { customers = [] } = {} } = {} } = useQuery(
    GET_CUSTOMER_CONTACT_DATA_FOR_RULES,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        pageSize: 10000,
        after: 1,
      },
    }
  );

  const [
    updateCustomerMappingRules,
    { loading: updateMutationLoading },
  ] = useMutation(UPDATE_INCOMING_ORDER_MAPPING_RULES);

  useEffect(() => {
    getOrderCustomerMappingRules({ variables: { type: 'customer' } });
  }, []);

  const onChange = (newValue, operations) => {
    console.log('ON_CUSTOMER_TABLE_CHANGE_', {
      newValue,
      operations,
    });

    setData(newValue);
  };

  const handleSaveChanges = async () => {
    try {
      const parsedDeletedRows = deletedRows.map(rowData =>
        parseCustomerMappingRulesForMutation({ ...rowData, enabled: false })
      );

      const parsedUpdatedRows = [];
      data.forEach(rowData => {
        const rowId = rowData?._id;
        const originalRow = initData.find(({ _id: id }) => id === rowId);

        if (
          rowId?.includes(NEW_ROW_ID_PREFIX) &&
          objectHasFieldsExceptId(rowData)
        ) {
          parsedUpdatedRows.push(parseCustomerMappingRulesForMutation(rowData));
        } else if (
          originalRow &&
          JSON.stringify(originalRow) !== JSON.stringify(rowData)
        ) {
          parsedUpdatedRows.push(parseCustomerMappingRulesForMutation(rowData));
        }
        return null;
      });

      const allUpdatedRows = [...parsedDeletedRows, ...parsedUpdatedRows];

      if (allUpdatedRows.length === 0) {
        setNotification({
          variables: {
            timeout: 4000,
            message: t('aiOrders.everything is up to date'),
            type: NOTIFICATION_STATUS.SUCCESS,
            isOpen: true,
          },
        });
        return;
      } else {
        const response = await updateCustomerMappingRules({
          variables: {
            rulesToUpdate: parsedUpdatedRows,
            rulesToDelete: parsedDeletedRows,
          },
          update: (_, result) => {
            const { data: { updateIncomingOrderMappingRules } = {} } = result;
            const errors = updateIncomingOrderMappingRules?.errors || [];
            const updatedRules = parseCustomerMappingRulesForUI(
              updateIncomingOrderMappingRules?.updated || []
            );

            if (errors.length === allUpdatedRows.length) {
              setNotification({
                variables: {
                  timeout: 4000,
                  message: t('aiOrders.errors.updating rules error', {
                    count: errors.length,
                  }),
                  type: NOTIFICATION_STATUS.ALERT,
                  isOpen: true,
                },
              });
            } else {
              setNotification({
                variables: {
                  timeout: 4000,
                  // prettier-ignore
                  message: `${t('aiOrders.success.updated rules', {
                    count: updatedRules.length,
                  })} \n ${
                    errors.length > 0
                      ? t('aiOrders.errors.updating rules error', { count: errors.length })
                      : ''
                  }`,
                  type: NOTIFICATION_STATUS.SUCCESS,
                  isOpen: true,
                },
              });
            }

            // NOTE: we need to reset errors on each update
            const errorsMap = new Map();
            errors.forEach(({ ruleId, error }) => {
              errorsMap.set(ruleId, error[language]);
            });

            const updateMap = new Map(
              updatedRules.map(rule => [rule._id, rule])
            );
            const updatedState = data.map(row => {
              const error = errorsMap?.get(row._id) || '';
              return {
                ...row,
                ...updateMap?.get(row._id),
                validationResult: error || '',
              };
            });

            setData(updatedState);
            setInitData(updatedState);

            if (parsedDeletedRows?.length > 0) {
              setDeletedRows([]);
            }
          },
        });
        console.log('ON_CUSTOMER_TABLE_CHANGE_    FINAL', {
          parsedDeletedRows,
          parsedUpdatedRows,
          allUpdatedRows,
          data,
          response,
        });
      }
    } catch (error) {
      console.error(error);
      setNotification({
        variables: {
          timeout: 4000,
          message: parseValidationErrors(error) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleOpenRevertChangesModal = () => {
    setRevertChangesModalOpen(true);
  };

  const handleCloseRevertChangesModal = () => {
    setRevertChangesModalOpen(false);
  };
  const handleRevertUnsavedChanges = () => {
    handleCloseRevertChangesModal();
    setData(initData);
    setDeletedRows([]);
  };

  const handleRouteToAllOrders = () => {
    history.push('/orders/ai');
  };

  const columns = [
    {
      ...keyColumn('ruleName', textColumn),
      title: t('aiOrders.customerMappingTable.rule title'),
      minWidth: 250,
    },
    {
      ...keyColumn('customerId', textColumn),
      title: t('aiOrders.customerMappingTable.i customer id'),
      minWidth: 150,
    },
    {
      ...keyColumn('customerEmail', textColumn),
      title: t('aiOrders.customerMappingTable.i customer email'),
      minWidth: 200,
    },
    {
      ...keyColumn('customerName', textColumn),
      title: t('aiOrders.customerMappingTable.i customer name'),
      minWidth: 170,
    },
    {
      ...keyColumn('customerAddress', textColumn),
      title: t('aiOrders.customerMappingTable.i customer address'),
      minWidth: 250,
    },
    {
      ...keyColumn('customerPhone', textColumn),
      title: t('aiOrders.customerMappingTable.i customer phone'),
      minWidth: 150,
    },
    {
      ...keyColumn('outputCustomerId', textColumn),
      title: t('aiOrders.customerMappingTable.o customer id'),
      component: function SelectCustomerCellHOC(props) {
        const { outputCustomerId } = props?.rowData || {};
        const customerData = outputCustomerId
          ? customers?.find(customer => customer._id === outputCustomerId)
          : null;

        return <CustomerSelectorCell {...props} customerData={customerData} />;
      },
      minWidth: 350,
    },
    {
      ...keyColumn('validationResult', textColumn),
      title: t('aiOrders.customerMappingTable.validation result'),
      id: 'validationResult',
      disabled: true,
      minWidth: 250,
      component: function ValidationCellHOC(props) {
        const { validationResult } = props?.rowData || {};

        return (
          <Tooltip title={validationResult}>
            <Typography style={{ padding: '0px 8px' }}>
              {validationResult}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  const isLoading = areRulesLoading || updateMutationLoading;

  console.log('CustomerMappingRules', {
    data,
    customerRules,
    deletedRows,
    customers,
    language,
  });

  return (
    <Grid container wrap="nowrap" direction="column" spacing={2}>
      <Grid
        className={classes.customerMappingTableActionsContainer}
        container
        item
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Button
          variant="outlined"
          className={classes.backButton}
          startIcon={
            <ArrowBackIosRoundedIcon fontSize="small" color="primary" />
          }
          onClick={handleRouteToAllOrders}
        >
          {t('common.back')}
        </Button>
        <Grid item>
          <Accordion className={classes.legendAccordion}>
            <AccordionSummary
              classes={{
                root: classes.legendAccordionSummary,
                expandIcon: classes.legendAccordionButtonExpand,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{t('aiOrders.legend and help')} ℹ️</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column" spacing={2}>
                <Grid item container>
                  <Typography component="span">
                    <b>{t('common.input')}</b>&nbsp;
                  </Typography>
                  <Typography>
                    {t('aiOrders.customer mapping input text')}
                  </Typography>
                </Grid>
                <Grid item container>
                  <Typography component="span">
                    <b>{t('common.output')}</b>&nbsp;
                  </Typography>
                  <Typography>
                    {t('aiOrders.customer mapping output text')}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid
          className={classes.customerMappingTableActions}
          container
          item
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="secondary"
            className={classes.revertChanges}
            onClick={handleOpenRevertChangesModal}
          >
            {t('aiOrders.revert changes')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveChanges}
          >
            {t('common.modal save changes')}
          </Button>
        </Grid>
      </Grid>
      <DataSheetGrid
        className={classNames(
          classes.customerMappingTableClass,
          isLoading && classes.disableTable
        )}
        value={data}
        onChange={onChange}
        columns={columns}
        height={customerTableHeight}
        rowHeight={50}
        disableExpandSelection
        // prettier-ignore
        rowKey={({ rowData, rowIndex }) => (rowData?._id?.includes(NEW_ROW_ID_PREFIX) ? rowIndex : rowData._id)}
        createRow={ADD_NEW_ROW}
        stickyRightColumn={{
          component: ({ deleteRow, rowData, rowIndex }) => (
            <StickyRightColumn
              rowData={rowData}
              rowIndex={rowIndex}
              deleteRow={deleteRow}
              setDataChanges={setDeletedRows}
            />
          ),
        }}
      />

      <ModalDialog
        isOpen={isRevertChangesModalOpen}
        onClose={handleCloseRevertChangesModal}
        title={t('aiOrders.revert changes')}
        actions={[
          {
            label: t('common.abort'),
            onClick: handleCloseRevertChangesModal,
          },
          { label: t('common.confirm'), onClick: handleRevertUnsavedChanges },
        ]}
      >
        <Typography variant="body1">
          {t('aiOrders.confirm reverting changes')}
        </Typography>
      </ModalDialog>
    </Grid>
  );
}

CustomerMappingRules.propTypes = {
  customerTableHeight: PropTypes.number,
};

CustomerMappingRules.defaultProps = {
  customerTableHeight: 400,
};

export default CustomerMappingRules;
