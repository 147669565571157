import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import CheckIcon from '@material-ui/icons/CheckOutlined';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ReviewIcon from '@material-ui/icons/FindInPageOutlined';
// import ReprocessIcon from '@material-ui/icons/RestorePageOutlined';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';

import { useSharedTableStyles } from 'components/shared/styles/sharedTableStyles';
import {
  INCOMING_ORDER_STATUSES_FOR_LEGEND,
  INCOMING_ORDER_STATUSES,
} from 'helpers/constants';
import CustomPopover from 'components/shared/Popover/CustomPopover';
import EmptyTableField from 'components/shared/EmptyTableField';
import StatusIconReducer from '../StatusIconReducer';

const useStyles = makeStyles(({ spacing }) => ({
  orderCustomerTitle: {
    width: 300,
  },
  businessName: {
    whiteSpace: 'break-spaces',
  },
  statusCircle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
  },
  deliveryDateTitle: {
    paddingLeft: spacing(0.5),
  },
  isClickableTitle: {
    cursor: 'pointer',
  },
}));

const AIOrdersTableRow = React.memo(
  ({ rowData, handleCancelOrder, handleAcceptOrder }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const location = useLocation();
    const history = useHistory();

    const sharedTableStyles = useSharedTableStyles();

    const [anchorEl, setAnchorEl] = useState(false);

    const {
      _id: orderId,
      customer,
      sender,
      receivedAt,
      status,
      orderNumber,
      deliveryOptions = [],
    } = rowData;

    const { contactData, vendorClientId } = customer || {};

    const parsedStatus = INCOMING_ORDER_STATUSES_FOR_LEGEND[status];

    const handleOpenActionMenu = event => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleCloseActionMenu = () => {
      setAnchorEl(null);
    };

    const handleStartOrderReview = () => {
      history.push(`/orders/ai-order-review?orderId=${orderId}`, {
        baseIncomingOrdersRoute: `${location.pathname}${location.search}`,
      });
    };

    const [clientId, clientName] = useMemo(() => {
      const name = contactData?.businessName;

      const statusPlaceholder =
        status === INCOMING_ORDER_STATUSES.PendingReview
          ? t('aiOrders.customer not found')
          : t('aiOrders.processing');

      return [vendorClientId || statusPlaceholder, name || statusPlaceholder];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorClientId, status, contactData]);

    const earliestDeliveryDate = useMemo(() => {
      const sortedOptions = deliveryOptions
        ?.map(delOp => delOp?.deliveryDate)
        .filter(Boolean)
        .sort((a, b) => new Date(a) - new Date(b));
      return sortedOptions?.[0]?.deliveryDate || null;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryOptions]);

    const isActionMenuOpen = Boolean(anchorEl);

    const isOrderImmutable =
      status === INCOMING_ORDER_STATUSES.Accepted ||
      status === INCOMING_ORDER_STATUSES.Rejected ||
      status === INCOMING_ORDER_STATUSES.Error;

    return (
      <TableRow className={sharedTableStyles.tableBodyRowV2}>
        <TableCell
          className={classNames(
            sharedTableStyles.tableCell,
            classes.orderCustomerTitle,
            classes.isClickableTitle
          )}
          role="button"
          onClick={handleStartOrderReview}
        >
          <Grid container direction="column" wrap="nowrap">
            <Typography className={classes.businessName} variant="body2">
              {orderNumber}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell
          className={classNames(
            sharedTableStyles.tableCell,
            classes.orderCustomerTitle,
            classes.isClickableTitle
          )}
          role="button"
          onClick={handleStartOrderReview}
        >
          <Grid container direction="column" wrap="nowrap">
            <Typography className={classes.businessName} variant="body2">
              {clientName}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell
          className={classNames(
            sharedTableStyles.tableCell,
            classes.orderCustomerTitle,
            classes.isClickableTitle
          )}
          role="button"
          onClick={handleStartOrderReview}
        >
          <Grid container direction="column" wrap="nowrap">
            <Typography className={classes.businessName} variant="body2">
              {sender.email}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell className={sharedTableStyles.tableCell}>
          <Typography variant="body2">{clientId}</Typography>
        </TableCell>
        <TableCell className={sharedTableStyles.tableCell}>
          <Typography className={classes.deliveryDateTitle} variant="body2">
            {moment(receivedAt).format('ddd DD.MM.YY / HH:mm')}
          </Typography>
        </TableCell>
        <TableCell className={sharedTableStyles.tableCell}>
          {earliestDeliveryDate ? (
            <Typography className={classes.deliveryDateTitle} variant="body2">
              {moment(earliestDeliveryDate).format('ddd DD.MM.YY')}
            </Typography>
          ) : (
            <EmptyTableField />
          )}
        </TableCell>
        <TableCell
          role="button"
          onClick={handleStartOrderReview}
          className={classNames(
            sharedTableStyles.tableCell,
            classes.isClickableTitle
          )}
          align="center"
        >
          {parsedStatus && (
            <Grid container justifyContent="center">
              <Tooltip title={t(`aiOrders.statuses.${parsedStatus.key}`)}>
                <div
                  key={parsedStatus.key}
                  className={classes.statusCircle}
                  style={{
                    background: parsedStatus.color,
                  }}
                >
                  <StatusIconReducer statusKey={parsedStatus.key} />
                </div>
              </Tooltip>
            </Grid>
          )}
        </TableCell>
        <TableCell
          className={classNames(
            sharedTableStyles.tableCell,
            sharedTableStyles.actionButtonContainer
          )}
        >
          {isOrderImmutable ? (
            <Grid
              container
              wrap="nowrap"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton onClick={handleStartOrderReview} size="medium">
                <ReviewIcon />
              </IconButton>
            </Grid>
          ) : (
            <Grid
              container
              wrap="nowrap"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton onClick={handleOpenActionMenu} size="medium">
                <MoreVertRoundedIcon />
              </IconButton>
            </Grid>
          )}
          <CustomPopover
            selectorIsOpen={isActionMenuOpen}
            anchorEl={anchorEl}
            handleCloseSelector={handleCloseActionMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <List
              component="nav"
              aria-label="list titles"
              disablePadding
              onClick={handleCloseActionMenu}
            >
              <ListItem
                className={classes.newsletterActionListItem}
                button
                onClick={handleStartOrderReview}
              >
                <ListItemIcon>
                  <ReviewIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.newsletterActionListItemText }}
                  primary={t('aiOrders.review')}
                />
              </ListItem>
              {/* <ListItem
              className={classes.newsletterActionListItem}
              button
              onClick={noop}
              >
              <ListItemIcon>
              <ReprocessIcon />
              </ListItemIcon>
              <ListItemText
              classes={{ primary: classes.newsletterActionListItemText }}
              primary={t('aiOrders.reprocess')}
              />
              </ListItem> */}
              <ListItem
                className={classes.newsletterActionListItem}
                button
                onClick={() => handleAcceptOrder(orderId)}
              >
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.newsletterActionListItemText }}
                  primary={t('common.confirm')}
                />
              </ListItem>
              <ListItem
                className={classes.newsletterActionListItem}
                button
                onClick={() => handleCancelOrder(orderId)}
              >
                <ListItemIcon>
                  <CancelRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.newsletterActionListItemText }}
                  primary={t('orders.order title')}
                />
              </ListItem>
            </List>
          </CustomPopover>
        </TableCell>
      </TableRow>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.rowData, nextProps.rowData)
);

AIOrdersTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  handleCancelOrder: PropTypes.func.isRequired,
  handleAcceptOrder: PropTypes.func.isRequired,
};

export default AIOrdersTableRow;
