import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';

import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { calculateClosestQuantity } from 'helpers/cartValidationHelpers';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  input: {
    padding: spacing(0.75, 1, 0.75, 0),
    height: '100%',
    '&:disabled': {
      color: palette.secondary.darkGray,
    },
  },
  inputRoot: {
    height: 32,
    color: palette.text.primary,
    backgroundColor: palette.background.paper,
    border: palette.border.grey,
    borderRadius: 4,
    '&.Mui-disabled': {
      backgroundColor: palette.background.muted,
      color: palette.secondary.darkGray,
    },
  },
  inputAdornment: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 20,
    minWidth: 20,
  },
  warningIcon: {
    color: palette.warning.main,
    width: 16,
    height: 16,
  },
}));

const ProductQtyInput = ({
  quantity,
  cartRestrictions,
  productRowId,
  handleChangeQty,
  disabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const {
    minimalQuantity = 1,
    cartQuantityStep = 1,
    fractionDigits = 0,
    // hasRestrictions = false,
    isCartStepInteger = true,
  } = cartRestrictions;

  const [cartQuantity, setCartQuantity] = useState(quantity || 0);

  useEffect(() => {
    if (quantity !== cartQuantity) {
      setCartQuantity(quantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  const handleOnKeyUp = e => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      inputRef.current.blur();
    }
  };

  const handleInputOnBlur = e => {
    const value = e?.target?.value;
    if (!value || value.length === 0 || parseFloat(value) === 0) {
      handleChangeQty(productRowId, 0);
    } else {
      handleChangeQty(productRowId, value);
    }
  };

  const handleLocalInputOnChange = e => {
    const value = e?.target?.value;
    if (value.length === 0 || parseFloat(value) === 0) {
      setCartQuantity(0);
    } else {
      setCartQuantity(+value);
    }
  };

  // -------------------- HELPERS --------------------

  const getTooltipMessage = () => {
    const isInvalidMinimum = cartQuantity < minimalQuantity;
    if (!isCartStepInteger && !isInvalidMinimum && cartQuantity > 0) {
      const currentQty = parseFloat(cartQuantity);
      if (currentQty < minimalQuantity) {
        return t('aiOrders.errors.minimum order quantity', {
          quantity: minimalQuantity,
        });
      }
      const qtySuggestion = calculateClosestQuantity(
        cartQuantity,
        cartRestrictions
      );
      const isValidQuantity = currentQty === qtySuggestion;
      return isValidQuantity
        ? ''
        : t('aiOrders.errors.cart quantity suggestion', {
            cartQuantityStep,
            calculatedValue: qtySuggestion,
          });
    } else if (isInvalidMinimum) {
      return t('aiOrders.errors.minimum order quantity', {
        quantity: minimalQuantity,
      });
    } else {
      return '';
    }
  };

  const tooltipMessage = getTooltipMessage();
  const showTooltip = tooltipMessage.length > 0;

  return (
    <Tooltip
      title={tooltipMessage}
      arrow
      placement="top"
      classes={{
        popper: classes.tooltipPopper,
        tooltip: classes.tooltip,
        touch: classes.tooltipTouch,
      }}
      PopperProps={{
        disablePortal: true,
      }}
    >
      <Grid container wrap="nowrap" alignItems="center">
        <NumericFormat
          key="cart-input-step"
          disabled={disabled}
          allowNegative={false}
          customInput={Input}
          inputRef={inputRef}
          decimalScale={isCartStepInteger ? 0 : fractionDigits}
          disableUnderline
          classes={{
            input: classes.input,
            root: classes.inputRoot,
          }}
          inputProps={{
            step: cartQuantityStep,
            min: 0,
          }}
          type="number"
          value={cartQuantity}
          valueIsNumericString
          allowedDecimalSeparators={isCartStepInteger ? null : [',', '.']}
          onChange={handleLocalInputOnChange}
          onBlur={handleInputOnBlur}
          onKeyUp={handleOnKeyUp}
          startAdornment={
            <InputAdornment className={classes.inputAdornment} position="start">
              {showTooltip ? (
                <WarningRoundedIcon className={classes.warningIcon} />
              ) : (
                ''
              )}
            </InputAdornment>
          }
        />
        {/* {isCartStepInteger ? (
          <NumericFormat
            key="integer-cart-step"
            allowNegative={false}
            customInput={Input}
            type="number"
            inputProps={{
              step: cartQuantityStep,
              min: minimalQuantity,
            }}
            decimalScale={0}
            inputRef={inputRef}
            disableUnderline
            classes={{
              input: classes.input,
              root: classes.inputRoot,
            }}
            value={cartQuantity}
            valueIsNumericString
            onChange={handleLocalInputOnChange}
            onBlur={handleInputOnBlur}
            onKeyUp={handleOnKeyUp}
            startAdornment={
              <InputAdornment className={classes.inputAdornment} position="start">
                {showTooltip ? (
                  <WarningRoundedIcon className={classes.warningIcon} />
                ) : null}
              </InputAdornment>
            }
          />
        ) : (
          <NumericFormat
            key="decimal-cart-step"
            allowNegative={false}
            customInput={Input}
            inputRef={inputRef}
            decimalScale={fractionDigits}
            disableUnderline
            classes={{
              input: classes.input,
              root: classes.inputRoot,
            }}
            inputProps={{
              step: cartQuantityStep,
              min: minimalQuantity,
            }}
            type="number"
            value={cartQuantity}
            valueIsNumericString
            allowedDecimalSeparators={[',', '.']}
            onChange={handleLocalInputOnChange}
            onBlur={handleInputOnBlur}
            onKeyUp={handleOnKeyUp}
            startAdornment={
              <InputAdornment className={classes.inputAdornment} position="start">
                {showTooltip ? (
                  <WarningRoundedIcon className={classes.warningIcon} />
                ) : null}
              </InputAdornment>
            }
          />
        )} */}
      </Grid>
    </Tooltip>
  );
};

ProductQtyInput.defaultProps = {
  quantity: null,
};

ProductQtyInput.propTypes = {
  quantity: PropTypes.number,
  cartRestrictions: PropTypes.shape({
    minimalQuantity: PropTypes.number,
    cartQuantityStep: PropTypes.number,
    fractionDigits: PropTypes.number,
    hasRestrictions: PropTypes.bool,
    isCartStepInteger: PropTypes.bool,
  }).isRequired,
  productRowId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChangeQty: PropTypes.func.isRequired,
};

export default ProductQtyInput;
