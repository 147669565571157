import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_INCOMING_ORDER_BY_ID } from 'graphql/query/incomingOrders';
import {
  ACCEPT_INCOMING_ORDER,
  UPDATE_INCOMING_ORDER_CUSTOMER,
  UPDATE_INCOMING_ORDER_DELIVERY_DATE,
  UPDATE_INCOMING_ORDER_PRODUCTS,
  UPDATE_INCOMING_ORDER_STATUS,
} from 'graphql/mutation/incomingOrders';
import { SET_NOTIFICATION } from 'graphql/mutation/user';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import noResultImage from 'assets/MessyDoodle.png';

import DocumentsPreview from 'components/AIOrderReview/DocumentsPreview';
import OrderDetails from 'components/AIOrderReview/OrderDetails';
import {
  INCOMING_ORDER_PRODUCTS_SORTING_OPTIONS,
  INCOMING_ORDER_STATUSES,
  NOTIFICATION_STATUS,
  ROWS_PER_PAGE_OPTIONS,
} from 'helpers/constants';
import Loader from 'components/shared/Loader';
import parseValidationErrors from 'helpers/parseValidationErrors';
import OrderModal from 'components/shared/ModalDialog';
import OrderReviewProcess from 'components/AIOrderReview/OrderReviewProcess';

const buildTheme = theme =>
  createTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MuiTypography: {
        body2: {
          fontWeight: 600,
        },
      },
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          overflow: 'visible',
        },
      },
      MuiPickersCalendarHeader: {
        daysHeader: {
          justifyContent: 'space-between',
        },
        dayLabel: {
          color: theme.palette.text.primary,
          textTransform: 'uppercase',
        },
        iconButton: {
          marginLeft: -8,
          marginRight: -8,
          color: theme.palette.primary.main,
          '& svg': {
            width: '1.25em',
            height: '1.25em',
          },
        },
        transitionContainer: {
          '& p': {
            fontSize: '1rem',
            fontWeight: 600,
          },
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          overflowX: 'visible',
        },
      },
      MuiPickersCalendar: {
        transitionContainer: {
          minHeight: 240,
          marginTop: 0,
        },
        week: {
          justifyContent: 'space-between',
        },
      },
      MuiPickersDay: {
        day: {
          width: 40,
          height: 40,
          '& p': {
            fontSize: '1rem',
          },
        },
        dayDisabled: {
          color: theme.palette.text.secondary,
          transition: 'none',
        },
      },
    },
  });

const useStyles = makeStyles(({ spacing }) => ({
  aiOrderReviewContainer: {
    position: 'relative',
    paddingTop: spacing(5),
  },
}));

export default function AIOrderReview() {
  const { t } = useTranslation();
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const currentPath = location.pathname;
  const { orderId = '' } = queries;

  const reviewProcessOrderIds = location?.state?.incomingOrdersForReview || [];
  const currentReviewOrderIndex = reviewProcessOrderIds.indexOf(orderId);
  const isAiOrdersReviewProcess =
    reviewProcessOrderIds?.length > 0 && currentReviewOrderIndex > -1;

  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 100, // DEMO: SET 100 or 200 for demo
    sortBy: INCOMING_ORDER_PRODUCTS_SORTING_OPTIONS.None,
    sortOrder: -1,
  });
  const [isOpenCancelOrderModal, setCancelOrderModal] = React.useState(false);

  const [
    updateIncomingOrderCustomer,
    { loading: isUpdateCustomerLoading },
  ] = useMutation(
    UPDATE_INCOMING_ORDER_CUSTOMER,
    {
      refetchQueries: [
        {
          query: GET_INCOMING_ORDER_BY_ID,
          variables: {
            _id: orderId,
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  const [
    updateIncomingOrderDeliveryDate,
    { loading: isUpdateDeliveryDateLoading },
  ] = useMutation(UPDATE_INCOMING_ORDER_DELIVERY_DATE);
  const [
    updateIncomingOrder,
    { loading: isUpdateIncomingOrderLoading },
  ] = useMutation(UPDATE_INCOMING_ORDER_PRODUCTS);
  const [acceptIncomingOrder, { loading: isAcceptOrderLoading }] = useMutation(
    ACCEPT_INCOMING_ORDER
  );
  const [updateOrderStatus] = useMutation(UPDATE_INCOMING_ORDER_STATUS);
  const [setNotification] = useMutation(SET_NOTIFICATION);

  const [
    getOrderById,
    { loading: isOrderLoading, error: orderErrors, data },
  ] = useLazyQuery(GET_INCOMING_ORDER_BY_ID, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    onError: error => {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: parseValidationErrors(error) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    },
  });

  useEffect(() => {
    let interval = null;

    if (interval) {
      clearInterval(interval);
      interval = null;
    }

    if (data?.getIncomingOrderById?.status === INCOMING_ORDER_STATUSES.InProgress) {
      interval = setInterval(() => {
        getOrderById({
          variables: {
            _id: orderId,
          },
        });
      }, 2000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [data?.getIncomingOrderById?.status]);

  const orderData = data && data.getIncomingOrderById;
  const orderDataProductsPagination = orderData?.products;

  React.useEffect(() => {
    const pageParam = +queries.page;
    const rowsParam = +queries.rowsPerPage;
    const sortByParam = queries.sortBy;
    const sortOrderParam = +queries.sortOrder;

    getOrderById({
      variables: {
        _id: orderId,
        after: pageParam + 1 || state.page + 1,
        pageSize: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      },
    });

    if (Object.keys(queries).length) {
      setState({
        ...state,
        page: pageParam || state.page,
        rowsPerPage: rowsParam || state.rowsPerPage,
        sortBy: sortByParam || state.sortBy,
        sortOrder: sortOrderParam || state.sortOrder,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  React.useEffect(() => {
    const totalPages = orderDataProductsPagination?.totalPages ?? 0;
    const pageNumberExceeded = !!totalPages && totalPages <= state.page;
    const rowsPerPageExceeded = !ROWS_PER_PAGE_OPTIONS.includes(
      state.rowsPerPage
    );

    if (
      orderDataProductsPagination &&
      (pageNumberExceeded || rowsPerPageExceeded)
    ) {
      history.replace({
        pathname: currentPath,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          page: state.page !== 0 && pageNumberExceeded ? 0 : state.page,
          rowsPerPage: rowsPerPageExceeded ? 10 : state.rowsPerPage,
        }),
      });
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDataProductsPagination]);

  const handleUpdateIncomingOrderCustomer = async customerId => {
    try {
      await updateIncomingOrderCustomer({
        variables: {
          orderId,
          customerId,
        },
        update: (_, result) => {
          const orderResponse = result.data.updateIncomingOrderCustomer;
          setNotification({
            variables: {
              timeout: 4000,
              message: t('aiOrders.success.customer changed', {
                orderId: orderResponse?.orderNumber,
              }),
              type: NOTIFICATION_STATUS.SUCCESS,
              isOpen: true,
            },
          });
        },
      });
    } catch (error) {
      console.error(error);
      setNotification({
        variables: {
          timeout: 4000,
          message: parseValidationErrors(error) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleUpdateIncomingOrderDeliveryDate = async (
    date,
    deliveryOptionId
  ) => {
    try {
      await updateIncomingOrderDeliveryDate({
        variables: {
          orderId,
          deliveryOptionId,
          date,
        },
      });
      setNotification({
        variables: {
          timeout: 4000,
          message: t('aiOrders.success.delivery date changed'),
          type: NOTIFICATION_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    } catch (error) {
      console.error(error);
      setNotification({
        variables: {
          timeout: 4000,
          message: parseValidationErrors(error) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
  };

  const handleCancelOrder = async () => {
    try {
      await updateOrderStatus({
        variables: {
          orderId,
          status: INCOMING_ORDER_STATUSES.Rejected,
        },
        update: (_, result) => {
          const orderResponse = result.data.updateIncomingOrderStatus;
          setNotification({
            variables: {
              timeout: 4000,
              message: t('orders.cancelOrder', {
                orderId: orderResponse?.orderNumber,
              }),
              type: NOTIFICATION_STATUS.SUCCESS,
              isOpen: true,
            },
          });
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: parseValidationErrors(error) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
    setCancelOrderModal(false);
  };

  const handleChangePage = async (event, page, search) => {
    const searchPhrase = search || queries.search;
    if (
      orderDataProductsPagination &&
      (orderDataProductsPagination.hasNextPage ||
        page + 1 < orderDataProductsPagination.totalPages)
    ) {
      try {
        await getOrderById({
          variables: {
            _id: orderId,
            searchPhrase,
            after: page + 1,
            pageSize: state.rowsPerPage,
            sortBy: state.sortBy,
            sortOrder: state.sortOrder,
          },
        });
      } catch (error) {
        console.error(error.message);
        setNotification({
          variables: {
            timeout: 4000,
            message:
              parseValidationErrors(error) || t('common.something wrong'),
            type: NOTIFICATION_STATUS.ALERT,
            isOpen: true,
          },
        });
      }
    }
    setState({ ...state, page });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        page,
      }),
    });
  };

  const handleChangeRowsPerPage = async event => {
    const rowsPerPage = +event.target.value;
    try {
      await getOrderById({
        variables: {
          _id: orderId,
          pageSize: rowsPerPage,
          after: 1,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: parseValidationErrors(error) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
    setState({ ...state, rowsPerPage, page: 0 });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        rowsPerPage,
        page: 0,
      }),
    });
  };

  const handleSortRows = sortLabel => async () => {
    const isAsc = state.sortBy === sortLabel && state.sortOrder === 1;
    const sortOrder = isAsc ? -1 : 1;
    try {
      await getOrderById({
        variables: {
          _id: orderId,
          pageSize: state.rowsPerPage,
          after: 1,
          sortBy: sortLabel,
          sortOrder,
        },
      });
    } catch (error) {
      console.error(error.message);
      setNotification({
        variables: {
          timeout: 4000,
          message: parseValidationErrors(error) || t('common.something wrong'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    }
    setState({
      ...state,
      sortOrder,
      sortBy: sortLabel,
      page: 0,
    });
    history.push({
      pathname: currentPath,
      search: queryString.stringify({
        rowsPerPage: state.rowsPerPage,
        page: 0,
        sortBy: sortLabel,
        sortOrder,
      }),
    });
  };

  const handleOpenCancelationModal = () => {
    setCancelOrderModal(true);
  };

  const handleCloseModal = () => {
    setCancelOrderModal(false);
  };

  if (!orderData) {
    return (
      <Grid container className={classes.aiOrderReviewContainer} wrap="nowrap">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          {orderErrors && <img src={noResultImage} alt="no result found" />}
          {isOrderLoading && <Loader />}
        </Grid>
      </Grid>
    );
  }

  const {
    orderNumber,
    documents = [],
    relatedOrders = [],
    customer,
    deliveryOptions,
    sender,
    receivedAt,
    status,
  } = orderData || {};

  const orderProducts = orderDataProductsPagination.products || [];

  const isOrderEditableByStatus =
    status !== INCOMING_ORDER_STATUSES.Accepted &&
    status !== INCOMING_ORDER_STATUSES.Rejected &&
    status !== INCOMING_ORDER_STATUSES.Received;

  const isEditingDisabled =
    isAcceptOrderLoading ||
    isUpdateCustomerLoading ||
    isUpdateDeliveryDateLoading ||
    isUpdateIncomingOrderLoading ||
    !isOrderEditableByStatus ||
    status === INCOMING_ORDER_STATUSES.InProgress ||
    isOrderLoading ||
    isUpdateCustomerLoading;

  // NOTE: leaving this console log for debugging purposes
  // eslint-disable-next-line no-console
  console.log('ORDER REVIEW CONTAINER', {
    orderId,
    orderErrors,
    orderData,
    orderDataProductsPagination,
    isOrderEditableByStatus,
    isEditingDisabled,
    history,
    location,
  });

  return (
    <Grid
      container
      className={classNames(
        isAiOrdersReviewProcess && classes.aiOrderReviewContainer
      )}
      wrap="nowrap"
    >
      {isAiOrdersReviewProcess && (
        <OrderReviewProcess
          reviewProcessOrderIds={reviewProcessOrderIds}
          currentReviewOrderIndex={currentReviewOrderIndex}
        />
      )}

      <DocumentsPreview
        documents={documents}
        orderNumber={orderNumber}
        relatedOrders={relatedOrders}
        orderId={orderId}
        orderStatus={status}
      />

      <ThemeProvider theme={buildTheme}>
        <OrderDetails
          orderId={orderId}
          orderNumber={orderNumber}
          customer={customer}
          sender={sender}
          deliveryOptions={deliveryOptions}
          receivedAt={receivedAt}
          status={status}
          orderProducts={orderProducts}
          isUpdateCustomerLoading={isUpdateCustomerLoading}
          isEditingDisabled={isEditingDisabled}
          isOrderEditableByStatus={isOrderEditableByStatus}
          handleUpdateIncomingOrderCustomer={handleUpdateIncomingOrderCustomer}
          handleUpdateIncomingOrderDeliveryDate={
            handleUpdateIncomingOrderDeliveryDate
          }
          handleOpenCancelationModal={handleOpenCancelationModal}
          updateIncomingOrder={updateIncomingOrder}
          acceptIncomingOrder={acceptIncomingOrder}
        />
      </ThemeProvider>
      <OrderModal
        isOpen={isOpenCancelOrderModal}
        title={t('orders.order title')}
        onClose={handleCloseModal}
        actions={[
          {
            label: t('orders.modalUndo'),
            onClick: handleCloseModal,
          },
          {
            label: t('orders.modal save changes'),
            onClick: handleCancelOrder,
          },
        ]}
      >
        <Typography align="center" variant="body1">
          {t('orders.modal message')}
        </Typography>
      </OrderModal>
    </Grid>
  );
}
