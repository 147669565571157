import { gql } from '@apollo/client';

export const RESCAN_ORDER = gql`
  mutation AiRescanOrder($orderId: ID!) {
    aiRescanOrder(orderId: $orderId) {
      status
      message
      jobId
    }
  }
`;
export const REMATCH_PRODUCTS = gql`
  mutation AiRematchOrderProducts($orderId: ID!) {
    aiRematchOrderProducts(orderId: $orderId) {
      status
      message
      jobId
    }
  }
`;
