import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { NEW_ROW_ID_PREFIX } from 'containers/AIRules/CustomerMappingRules';

const useStyles = makeStyles(() => ({
  deleteCustomerRowIcon: {
    fontSize: 16,
  },
}));

function StickyRightColumn({ rowData, rowIndex, deleteRow, setDataChanges }) {
  const classes = useStyles();

  const handleDeleteRow = () => {
    if (!rowData._id.includes(NEW_ROW_ID_PREFIX)) {
      setDataChanges(prevState => [...prevState, rowData]);
    }
    deleteRow(rowIndex);
  };

  return (
    <Grid container alignContent="center" justifyContent="center">
      <IconButton
        className={classes.deleteCustomerRowIcon}
        onClick={handleDeleteRow}
      >
        ❌
      </IconButton>
    </Grid>
  );
}

StickyRightColumn.propTypes = {
  rowData: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  deleteRow: PropTypes.func.isRequired,
  setDataChanges: PropTypes.func.isRequired,
};

export default StickyRightColumn;
