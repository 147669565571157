import React, { memo, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import BackRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';

import DocumentIconReducer from 'components/AIOrderReview/DocumentIconReducer';
import Loader from 'components/shared/Loader';
import { INCOMING_ORDER_STATUSES } from 'helpers/constants';
import isEqual from "lodash/isEqual";

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
  documentPreviewContainer: {
    height: '100%',
    marginRight: spacing(1),
    position: 'sticky',
    top: 120,
    maxWidth: 700,
  },
  backButton: {
    fontSize: typography.pxToRem(16),
    marginBottom: spacing(2),
  },
  documentNumber: {
    marginBottom: spacing(3),
  },
  previewPaper: {
    height: '100%',
    width: '100%',
    borderRadius: 8,
    overflow: 'hidden',
  },
  previewContainer: {
    height: 600,
    background: palette.background.border,
    padding: spacing(3, 2, 0),
  },
  previewEmbed: {
    width: '100%',
    height: '100%',
  },
  documents: {
    padding: spacing(2),
  },
  documentsNoteContainer: {
    fontWeight: typography.fontWeightBold,
    marginBottom: spacing(1),
  },
  documentsNote: {
    color: palette.text.secondary,
  },
  documentsNoteAction: {
    textDecoration: 'underline',
    color: palette.primary.main,
    marginLeft: spacing(1),
    cursor: 'pointer',
    fontSize: typography.pxToRem(12),
  },
  availableDocumentsContainer: {
    overflow: 'auto',
    paddingBottom: spacing(2),
  },
  documentButton: {
    height: 56,
    borderRadius: 8,
    marginRight: spacing(1.25),
    border: palette.border.grey,
    minWidth: 'fit-content',
  },
  activeDocumentButton: {
    border: `1px solid ${palette.primary.main}`,
    backgroundColor: 'rgba(255, 81, 0, 0.04)',
  },
  startIcon: {
    marginLeft: spacing(1),
  },
  dialogPaper: {
    width: 400,
    maxHeight: 400,
    padding: spacing(3),
    borderRadius: 8,
  },
  otherOrdersTitle: {
    fontWeight: typography.fontWeightBold,
    marginBottom: spacing(1),
  },
  otherOrderItem: {
    height: 48,
    padding: spacing(1, 2),
    borderRadius: 8,
    border: palette.border.grey,
    marginBottom: spacing(1.5),
  },
  goToOrderButton: {
    borderRadius: 120,
    height: 32,
  },
  otherOrderTitle: {
    fontSize: typography.pxToRem(12),
    fontWeight: typography.fontWeightBold,
  },
}));

function DocumentsPreview({ orderNumber, documents, relatedOrders, orderId, orderStatus }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const history = useHistory();

  const [previewSrc, setPreviewSrc] = useState({
    url: '',
    _id: '',
    type: 'email',
    error: false,
  });
  const [isAllOrdersModalOpen, setAllOrdersModalOpen] = useState(false);

  useEffect(() => {
    if (documents.length) {
      setPreviewSrc({ ...documents[0], error: false });
    }
  }, [documents]);

  const handleChangeDocument = documentData => () => {
    setPreviewSrc({ ...documentData, error: false });
  };

  const handleOpenOtherOrdersModal = () => {
    setAllOrdersModalOpen(true);
  };

  const handleCloseOtherOrdersModal = () => {
    setAllOrdersModalOpen(false);
  };

  const handleGoBack = () => {
    const { baseIncomingOrdersRoute } = history.location?.state || {};
    if (baseIncomingOrdersRoute) {
      history.push(baseIncomingOrdersRoute);
    } else {
      history.push('/orders/ai');
    }
  };

  const goToOrder = (orderId) => {
    history.push(`/orders/ai-order-review?orderId=${orderId}`);
  };

  const hasMultipleDocuments = documents.length > 1;
  const hasRelatedOrders = relatedOrders.length > 1;
  const inProgress = orderStatus === INCOMING_ORDER_STATUSES.IN_PROGRESS;

  return (
    <Grid
      className={classes.documentPreviewContainer}
      container
      item
      xs={6}
      direction="column"
      alignItems="flex-start"
    >
      {inProgress && (
        <Loader
          styles={{
            backgroundColor: 'rgba(255, 81, 0, 0.5)',
            zIndex: 1000000,
            position: 'absolute',
            top: 0,
            height: '100%'
          }}
        />
      )}
      <Button
        onClick={handleGoBack}
        className={classes.backButton}
        startIcon={<BackRoundedIcon />}
      >
        {t('aiOrders.back to overview')}
      </Button>

      <Paper elevation={0} className={classes.previewPaper}>
        <Grid className={classes.previewContainer} container>
          {previewSrc.url && (
            <embed
              title={orderNumber}
              className={classes.previewEmbed}
              src={`${previewSrc.url}#navpanes=0`}
            />
          )}
        </Grid>
        <Grid className={classes.documents} container direction="column">
          {hasRelatedOrders && (
            <Typography
              component="p"
              className={classes.documentsNoteContainer}
              variant="caption"
            >
              <span className={classes.documentsNote}>
                {t('aiOrders.note')}:&nbsp;
              </span>
              {t('aiOrders.preview orders qty', {
                numOfOrder: 1,
                totalOrders: relatedOrders.length,
              })}
              <Button
                onClick={handleOpenOtherOrdersModal}
                className={classes.documentsNoteAction}
              >
                {t('aiOrders.view all orders')}
              </Button>
            </Typography>
          )}
          <Grid
            container
            className={classes.availableDocumentsContainer}
            wrap="nowrap"
          >
            {documents.map(document => {
              const isActive = document._id === previewSrc._id;
              const Icon = DocumentIconReducer(document.type);
              return (
                <Button
                  key={document._id}
                  className={classNames(
                    classes.documentButton,
                    isActive && classes.activeDocumentButton
                  )}
                  classes={{
                    startIcon: classes.startIcon,
                  }}
                  onClick={handleChangeDocument(document)}
                  startIcon={Icon}
                >
                  {document.documentName}
                </Button>
              );
            })}
          </Grid>
          {/* FIXME: EMAIL-CONVERTER: should be other orders from pdf or order itself  */}
          <Dialog
            aria-labelledby="other-orders-dialog"
            open={isAllOrdersModalOpen}
            hidden={!hasRelatedOrders}
            classes={{ paper: classes.dialogPaper }}
            PaperProps={{ elevation: 0 }}
          >
            <Grid
              container
              alignContent="center"
              justifyContent="space-between"
            >
              <Typography className={classes.otherOrdersTitle} variant="h5">
                {t('aiOrders.other orders')}
              </Typography>
              <IconButton
                color="secondary"
                onClick={handleCloseOtherOrdersModal}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
            <List component="div" role="list">
              {relatedOrders.map(doc => (
                <ListItem
                  className={classes.otherOrderItem}
                  key={doc._id}
                  role="listitem"
                >
                  <Typography className={classes.otherOrderTitle}>
                    #{doc._id}
                  </Typography>
                  <ListItemSecondaryAction>
                    <Button
                      className={classes.goToOrderButton}
                      variant="contained"
                      color="primary"
                      onClick={() => goToOrder(doc._id)}
                      disabled={doc._id === orderId}
                    >
                      {t('aiOrders.go to order')}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Dialog>
        </Grid>
      </Paper>
    </Grid>
  );
}

DocumentsPreview.propTypes = {
  orderNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      documentName: PropTypes.string.isRequired,
      documentNumber: PropTypes.string,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  relatedOrders: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    })
  ).isRequired,
  orderId: PropTypes.string.isRequired,
  orderStatus: PropTypes.string.isRequired,
};

export default memo(
  DocumentsPreview,
  (prevProps, nextProps) => {
    return (
      prevProps.orderStatus === nextProps.orderStatus ||
      prevProps.orderId === nextProps.orderId ||
      prevProps.orderNumber === nextProps.orderNumber ||
      isEqual(prevProps.relatedOrders, nextProps.relatedOrders)
    );
  }
);
