import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEqual, noop } from 'lodash';

import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import StyledInput from 'components/shared/Inputs/StyledInput';

const useStyles = makeStyles(({ palette }) => ({
  inputRoot: {
    cursor: 'default',
    height: 32,
    borderRadius: 4,
    '&.Mui-disabled': {
      backgroundColor: palette.background.muted,
      color: palette.secondary.darkGray,
    },
  },
  readOnlyInputRoot: {
    cursor: 'default',
    height: 32,
    borderRadius: 4,
    backgroundColor: palette.background.muted,
    color: palette.secondary.darkGray,
  },
  disabled: {
    cursor: 'default',
    backgroundColor: palette.background.muted,
    color: palette.secondary.darkGray,
  },
  readOnlyInput: {
    cursor: 'default',
    backgroundColor: palette.background.muted,
    color: palette.secondary.darkGray,
  },
}));

function ProductsPackagingSelector({
  productRowId,
  variants,
  activeVariant,
  isEditingDisabled,
  handlePackagingChange,
}) {
  const classes = useStyles();

  const handlePackageChange = event => {
    handlePackagingChange(productRowId, event.target.value);
  };

  return variants.length === 1 ? (
    <StyledInput
      variant="outlined"
      value={activeVariant.packaging}
      onChange={noop}
      readOnly
      classes={{
        root: classes.readOnlyInputRoot,
        input: classes.readOnlyInput,
      }}
      fullWidth
    />
  ) : (
    <Select
      value={activeVariant.packaging}
      onChange={handlePackageChange}
      displayEmpty
      disabled={isEditingDisabled}
      className={classes.selectEmpty}
      classes={{
        disabled: classes.disabled,
      }}
      input={
        <StyledInput
          variant="outlined"
          classes={{
            root: classes.inputRoot,
          }}
          fullWidth
        />
      }
    >
      {variants.map(variant => (
        <MenuItem key={variant.packaging} value={variant.packaging}>
          {variant.packaging}
        </MenuItem>
      ))}
    </Select>
  );
}

ProductsPackagingSelector.propTypes = {
  productRowId: PropTypes.string.isRequired,
  variants: PropTypes.array,
  activeVariant: PropTypes.object,
  isEditingDisabled: PropTypes.bool.isRequired,
  handlePackagingChange: PropTypes.func.isRequired,
};

ProductsPackagingSelector.defaultProps = {
  variants: [],
  activeVariant: {},
};

export default memo(
  ProductsPackagingSelector,
  (prevProps, nextProps) =>
    isEqual(prevProps.activeVariant, nextProps.activeVariant) &&
    prevProps.isEditingDisabled === nextProps.isEditingDisabled
);
