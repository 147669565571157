import { getCustomerAddress } from './getCustomerAddress';

export function getCustomerContactData(customerData) {
  if (!customerData) {
    return '';
  } else {
    const { contactData, vendorClientId } = customerData;
    return `${vendorClientId} | ${contactData.businessName} |
     ${getCustomerAddress(contactData)}`;
  }
}
