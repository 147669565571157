import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

import OrderDetailsCustomerSearch from 'components/AIOrderReview/OrderDetailsCustomerSearch';
import { getCustomerContactData } from 'helpers/getCustomerContactData';

const useStyles = makeStyles(({ palette, spacing }) => ({
  customerSelectorCellContainer: {
    height: '100%',
  },
  orderDetailMainPaper: {
    height: '100%',
    padding: spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: palette.background.paper,
  },
  clickablePaper: {
    cursor: 'pointer',
    '&:hover': {
      background: palette.action.hover,
    },
  },
  searchCustomerIcon: {
    width: 20,
    height: 20,
    marginRight: spacing(1),
  },
  customerInfoTextbox: {
    padding: spacing(0.5),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
}));

export default function CustomerSelectorCell({
  rowData,
  customerData,
  setRowData,
  // ...otherDataSheetProps // NOTE: ALL OTHER PROPS ARE AVAILABLE HERE
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const cellRef = React.useRef(null);

  const [customerAnchorEl, setCustomerAnchorEl] = useState(null);

  const customerInfo = getCustomerContactData(customerData);

  const handleOpenCustomerSearch = () => {
    setCustomerAnchorEl(cellRef.current);
  };

  const handleCloseCustomerSearch = () => {
    setCustomerAnchorEl(null);
  };

  const handleSetCustomer = customerId => {
    setRowData({ ...rowData, outputCustomerId: customerId });
    handleCloseCustomerSearch();
  };

  return (
    <Grid
      className={classes.customerSelectorCellContainer}
      container
      ref={cellRef}
      wrap="nowrap"
      alignItems="center"
    >
      <Grid container justifyContent="center" item>
        {customerInfo ? (
          <Tooltip title={customerInfo}>
            <Typography
              align="center"
              className={classes.customerInfoTextbox}
              variant="body2"
            >
              {customerInfo}
            </Typography>
          </Tooltip>
        ) : (
          <Typography align="center" variant="body2" noWrap>
            {t('aiOrders.not set')} ⚠️
          </Typography>
        )}
      </Grid>
      <Grid
        item
        className={classNames(
          classes.orderDetailMainPaper,
          classes.clickablePaper
        )}
        role="button"
        onClick={handleOpenCustomerSearch}
      >
        <SearchIcon className={classes.searchCustomerIcon} />
        <Typography variant="body2" noWrap>
          {t('common.search')}
        </Typography>
      </Grid>
      <OrderDetailsCustomerSearch
        anchorEl={customerAnchorEl}
        handleClose={handleCloseCustomerSearch}
        handleSetCustomer={handleSetCustomer}
      />
    </Grid>
  );
}

CustomerSelectorCell.propTypes = {
  rowData: PropTypes.object.isRequired,
  customerData: PropTypes.object,
  setRowData: PropTypes.func.isRequired,
};

CustomerSelectorCell.defaultProps = {
  customerData: null,
};
