import { activeAccountIdVar } from 'graphql/apolloReactiveVariables';

export const objectHasFieldsExceptId = obj =>
  Object.entries(obj).some(
    ([key, value]) => key !== '_id' && value !== undefined
  );

export const parseCustomerMappingRulesForMutation = rawTableCell => {
  const parsedTableCell = {
    type: 'customer',
    ruleAppliedToId: activeAccountIdVar(),
    assignedToGroups: [],
    assignedToCustomers: [],
    assignedToAll: false,
    enabled: true,
    conditions: [],
    actions: [],
  };
  Object.entries(rawTableCell).forEach(([key, value]) => {
    switch (key) {
      case '_id':
        parsedTableCell._id = value;
        break;
      case 'ruleName':
        parsedTableCell.ruleName = value;
        break;
      case 'enabled':
        parsedTableCell.enabled = value;
        break;

      case 'customerAddress':
      case 'customerEmail':
      case 'customerId':
      case 'customerName':
      case 'customerPhone':
        if (value) {
          parsedTableCell.conditions.push({
            fieldPath: key,
            operation: 'eq',
            value,
          });
        }
        break;
      case 'outputCustomerId':
        if (value) {
          parsedTableCell.actions.push({
            field: 'customerId',
            type: 'replaceCustomer',
            value,
          });
        }
        break;
      default:
        break;
    }
  });

  return parsedTableCell;
};

export const parseCustomerMappingRulesForUI = rules =>
  rules.map(rule => {
    const ruleObject = {
      _id: rule._id,
      ruleName: rule.ruleName,
      customerId: '',
      customerEmail: '',
      customerName: '',
      customerAddress: '',
      customerPhone: '',
      outputCustomerId: rule?.actions?.[0]?.value || '',
      validationResult: '',
    };

    rule.conditions.forEach(condition => {
      ruleObject[condition.fieldPath] = condition.value;
    });
    return ruleObject;
  });
