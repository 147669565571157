import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

const useStyles = makeStyles(({ typography, spacing, palette }) => ({
  container: {
    width: 'fit-content',
    maxWidth: 'max-content',
    height: 50,
  },
  switchOrderRouteButton: {
    fontWeight: typography.fontWeightBold,
    textTransform: 'none',
    display: 'flex',
    whiteSpace: 'nowrap',
    height: 40,
    borderRadius: 40,
    boxShadow: 'none',
    '&:not(:first-child)': {
      margin: spacing(0, 1),
    },
    '&:last-child': {
      marginRight: spacing(1),
    },
  },
  inActiveButton: {
    border: palette.border.grey,
    background: palette.background.mutedLight,
    color: palette.text.primary,
  },
}));

export default function SwitchAITablesButtons() {
  const classes = useStyles();

  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const handleChangeOrderRoute = newValue => () => {
    history.push(newValue);
  };

  const TAB_OPTIONS = [
    {
      title: 'All Orders',
      route: '/orders/ai',
    },
    // {
    //   title: 'Product Rules',
    //   route: '/orders/ai/product-rules',
    // },
    {
      title: 'Customer Rules',
      route: '/orders/ai/customer-rules',
    },
    // {
    //   title: 'Auto Accept Rules',
    //   route: '/orders/ai-auto-accept-rules',
    // },
  ];

  const currentPath = location.pathname;

  console.log('SwitchAITablesButtons', {
    history,
    location,
    TAB_OPTIONS,
  });
  return (
    <Grid
      container
      className={classes.container}
      wrap="nowrap"
      alignItems="flex-end"
    >
      {TAB_OPTIONS.map(({ title, route }) => {
        const isActiveButton = route === currentPath;
        return (
          <Button
            key={route}
            variant="contained"
            color={isActiveButton ? 'secondary' : 'default'}
            onClick={handleChangeOrderRoute(route)}
            className={classNames(
              classes.switchOrderRouteButton,
              !isActiveButton && classes.inActiveButton
            )}
          >
            {t(title)}
          </Button>
        );
      })}
    </Grid>
  );
}
